type TBody<T> = {
  limit: number
  page: number
  lastPage: number
  total: number
  offset: number
  data: T[],
}

export class Paginate<T> {
  #limit: number
  #page: number
  #lastPage: number
  #total: number
  #offset: number
  #data: T[]

  get limit() {
    return this.#limit
  }
  get page() {
    return this.#page
  }
  get total() {
    return this.#total
  }
  get lastPage() {
    return this.#lastPage
  }
  get offset() {
    return this.#offset
  }
  get data() {
    return this.#data
  }

  constructor({ limit, page, lastPage, total, offset, data }: TBody<T>) {
    this.#limit = limit
    this.#page = page
    this.#lastPage = lastPage
    this.#total = total
    this.#offset = offset
    this.#data = data
  }

}
