import { EnvioDestino } from "./envioDestino";
import { IEnvio } from "./iEnvio";
import { Cliente } from "@/boundedContext/usuario/domain/entities/cliente";
import { EnvioVariable } from "./envioVariable";
import { CreatedAt } from "@/boundedContext/shared/domain/entities/createdAt";
import { UpdatedAt } from "@/boundedContext/shared/domain/entities/updatedAt";
import { Mensajero } from "@/boundedContext/usuario/domain/entities/mensajero";
import { EnvioOrigen } from "./envioOrigen";
import { RedServicio } from "@/boundedContext/red/domain/entities/redServicio";
import { EnvioRecibido } from "./envioRecibido";
import { EnvioCotizacion } from "./envioCotizacion";
import { SerVehiculo } from "@/boundedContext/red/domain/entities/serVehiculo";
import { IEnvioRecibido } from "./iEnvioRecibido";

interface IPais {
  id: number;
  name: string;
}
interface IDpto {
  id: number;
  name: string;
  pais: IPais;
}

interface ICiudad {
  id: number;
  name: string;
  dpto: IDpto;
}
interface IMensajero {
  id: string;
  nombres: string;
  apellido1: string;
}

interface IDestino {
  direccion: string;
  codigoPostal: string;
  nombre: string;
  contacto: string;
  movil: string;
  ciudad: ICiudad;
  correo: string;
  dni: string | null;
  longitud: number;
  latitud: number;
  observacion: string | null;
  ciudadId: number;
  complemento: string;
}

interface IOrigen {
  direccion: string;
  codigoPostal: string;
  nombre: string;
  contacto: string;
  movil: string;
  clienteSedeId: number | null;
  ciudadId: number;
  dni: string | null;
  correo: string;
  ciudad: ICiudad;
  observacion: string | null;
  latitud: number;
  longitud: number;
  complemento: string;
}
interface ICliente{
  id: string;
  razonSocial: string;
  logo: string;
  contactoTelefono: string;
  facturacionCorreo: string;
  nifcif:string;
  direccion: string;
  isOpen: boolean;
}
interface IEnvioVariable  {
  cantidad:number
  nombre: string
  redVariableId: number
  valor: number
}

interface IServicio {
  id: number;
  esActivo: boolean;
  nombre: string;
  redId: number;
  color: string;
  cantidadKilometros: number;
  canRecogidaFecha: boolean;
  canEnvioFecha: boolean;
  radioKm: number;
}

interface IVehiculo {
  id: string;
  nombre: string;
  imagen: string;
  capacidad: number;
}
interface ICotizacion {
  iva: number;
  total: number;
  subtotal: number;
  envioCosto: number;
  id: string;
  envioId: string;
}

interface RawData {
  variables: IEnvioVariable[];
  id:string;
  coste: number;
  costeInfo: string;
  cliente: ICliente;
  redServicioId: number;
  consecutivo: number;
  entregaFecha: string;
  entregaHora: string;
  estado: string;
  destino: IDestino;
  origen: IOrigen;
  servicio: IServicio;
  portes: number;
  tipo: number;
  impuestoId: number;
  contraReembolso: number;
  valorDeclarado: number;
  ayudantesCantidad: number;
  bultosCantidad: number;
  peso: number;
  kmsAdicionales:number;
  kmsDistancia: number;
  kmsIncluidos: number;
  recogerFecha: string;
  recogerDesde: string;
  recogerHasta: string;
  createdAt: string;
  updatedAt: string;
  mensajero: IMensajero;
  recibido?: IEnvioRecibido;
  serVehId: number | null;
  observaciones: string;
  vehiculo: IVehiculo;
  cotizacion: ICotizacion;

}

export type TBody = {
  vehiculo: SerVehiculo | undefined;
  observaciones: string;
  serVehId: number | null;
  id: string;
  coste: number;
  consecutivo: number;
  recogerFecha: string;
  recogerDesde: string;
  estado:string;
  redServicioId: number;
  cliente: Cliente;
  variables: EnvioVariable[];
  mensajero: Mensajero;
  createdAt: CreatedAt;
  updatedAt: UpdatedAt;
  origen: EnvioOrigen;
  destino: EnvioDestino;
  servicio: RedServicio;
  recibido: EnvioRecibido | undefined;
  cotizacion: EnvioCotizacion;
}
export class Envio implements IEnvio {
  id: string;
  coste: number;
  consecutivo: number;
  recogerFecha: string;
  recogerDesde: string;
  estado: string;
  redServicioId: number;
  cliente: Cliente;
  variables: EnvioVariable[];
  createdAt: CreatedAt;
  updatedAt: UpdatedAt;
  mensajero: Mensajero;
  origen: EnvioOrigen;
  destino: EnvioDestino;
  servicio: RedServicio;
  serVehId: number | null;
  recibido?: EnvioRecibido | undefined;
  cotizacion: EnvioCotizacion;
  observaciones: string;
  vehiculo?: SerVehiculo | undefined;

  constructor(body:TBody){
    this.id = body.id
    this.coste = body.coste
    this.consecutivo = body.consecutivo
    this.recogerFecha = body.recogerFecha;
    this.recogerDesde = body.recogerDesde;
    this.estado = body.estado
    this.redServicioId = body.redServicioId
    this.createdAt = body.createdAt
    this.updatedAt = body.updatedAt
    this.servicio = body.servicio
    this.serVehId = body.serVehId
    this.recibido = body.recibido
    this.observaciones = body.observaciones

    this.origen = body.origen
    this.destino = body.destino

    this.variables = body.variables

    this.mensajero = body.mensajero
    this.vehiculo = body.vehiculo
    this.cliente = body.cliente
    this.cotizacion =body.cotizacion
  }

  static toDomain(rawData: RawData) {
    //console.log(222222,rawData);
    return new Envio({
      id: rawData.id,
      coste: rawData.coste,
      consecutivo: rawData.consecutivo,
      estado: rawData.estado,
      redServicioId: rawData.redServicioId,
      recogerFecha: rawData.recogerFecha,
      recogerDesde: rawData.recogerDesde,
      createdAt: new CreatedAt(rawData.createdAt),
      updatedAt: new UpdatedAt(rawData.updatedAt),
      mensajero: rawData.mensajero,
      observaciones: rawData.observaciones,
      recibido: rawData.recibido,
      serVehId: rawData.serVehId,
      destino: EnvioDestino.toDomain({
        codigoPostal: rawData.destino.codigoPostal,
        direccion: rawData.destino.direccion,
        movil: rawData.destino.movil,
        nombre: rawData.destino.nombre,
        correo: rawData.destino.correo,
        dni: rawData.destino.dni,
        ciudadId: rawData.destino.ciudadId,
        ciudad: rawData.destino.ciudad,
        latitud: rawData.destino.latitud,
        longitud: rawData.destino.longitud,
        complemento: rawData.destino.complemento,
      }),
      origen: EnvioOrigen.toDomain({
        codigoPostal: rawData.origen.codigoPostal,
        direccion: rawData.origen.direccion,
        nombre: rawData.origen.nombre,
        movil: rawData.origen.movil,
        ciudadId: rawData.origen.ciudadId,
        dni: rawData.origen.dni,
        correo: rawData.origen.correo,
        ciudad: rawData.origen.ciudad,
        latitud: rawData.origen.latitud,
        longitud: rawData.origen.longitud,
        complemento: rawData.origen.complemento
      }),
      variables: rawData.variables.map(a => EnvioVariable.toDomain(a)),
      cliente: Cliente.toDomain({
        contactoTelefono: rawData.cliente.contactoTelefono,
        facturacionCorreo: rawData.cliente.facturacionCorreo,
        id: rawData.cliente.id,
        logo: rawData.cliente.logo,
        razonSocial: rawData.cliente.razonSocial,
        direccion: rawData.cliente.direccion,
        nifcif: rawData.cliente.nifcif,
        isOpen: rawData.cliente.isOpen,
      }),
      servicio: RedServicio.toDomain(rawData.servicio),
      cotizacion: EnvioCotizacion.toDomain({
        ...rawData.cotizacion,
        total: +rawData.cotizacion.total,
        subtotal: +rawData.cotizacion.subtotal,
        iva: +rawData.cotizacion.iva,
        envioCosto: +rawData.cotizacion.envioCosto
      }),
      vehiculo: SerVehiculo.toDomain(rawData.vehiculo),
    })
  }
}
