import authMiddleware from '@/middleware/auth.middleware'
import userEnterRoute from '@/middleware/checkEnterRoute.middleware'
export default [
  {
    path: '/configuracion/ubicaciones',
    name: 'configuracion.ubicaciones',
    component: ()=>import('./listarPaises'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'UBICACIONES PAISES',
      permiso:'configuracion_ubicacion_paises_acceso'
    }
  },
  
  // sección estados
  {
    path: '/configuracion/ubicaciones/estados/:idPais',
    name: 'configuracion.ubicaciones.estados',
    component: ()=>import('./listarEstados'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'UBICACIONES ESTADOS',
      permiso:'configuracion_ubicacion_provincias_acceso'
    }
  },
  {
    path: '/configuracion/ubicaciones/estados/form/:id?',
    name: 'estados.form',
    component: ()=>import('./listarEstados/form'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'FORMULARIO ESTADOS',
      permiso:'configuracion_ubicacion_estados_crear_acceso'
      
    }
  },
  //seccion ciudades
  {
    path: '/configuracion/ubicaciones/ciudades/:idEstado',
    name: 'configuracion.ubicaciones.ciudades',
    component: ()=>import('./listarCiudades'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'UBICACIONES CIUDADES',
      permiso:'configuracion_ubicacion_ciudades_acceso'
    }
  },
  {
    path: '/configuracion/ubicaciones/ciudades/form/:id?',
    name: 'ciudades.form',
    component: ()=>import('./listarCiudades/form'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'FORMULARIO CIUDADES',
      permiso:'configuracion_ubicacion_ciudades_crear_acceso'
    }
  },
  //seccion codigo postal
  {
    path: '/configuracion/ubicaciones/codigo-postal/:ciudadId',
    name: 'configuracion.ubicaciones.codigosPostales',
    component: ()=>import('./listasCodPostales'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'CÓDIGO POSTAL',
      permiso:'configuracion_ubicacion_codPostal_acceso'
    }
  },
  {
    path: '/configuracion/ubicaciones/codigo-postal/form/:id?',
    name: 'codigosPostales.form',
    component: ()=>import('./listasCodPostales/form'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'FORMULARIO CODIGO POSTAL',
      permiso:'configuracion_ubicacion_codPostal_crearEditar_acceso'
    }
  },
]