import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import Toast from 'primevue/toast'
import socket from '@/libs/socket.lib'
import {useToast} from 'primevue/usetoast'

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const toast = useToast()
socket.on('centro-notificaciones',(value)=>{
  console.log('socket centro-notificaciones',{value});
  toast.add({
    severity: 'info',
    summary: value.title,
    detail: value.message,
    group: 'grupo1',
    life: 4000,
  })
})
socket.on('envios.notiticaciones',(value)=>{
  //console.log('envios.notiticaciones',value);
  //const data = JSON.parse(value.data)
  //console.log(data);
  new Audio('/sounds/notification.mp3').play()
  toast.add({
    severity: 'info',
    summary: value.title,
    detail: value.message,
    group: 'grupo1',
    life: 1000 * 10,
  })
})


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createVNode(_unref(Toast)),
    _createVNode(_unref(Toast), { group: "grupo1" })
  ], 64))
}
}

})