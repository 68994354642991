import { IEnvioRepository } from "../domain/contracts/iEnvioRepository";
import { Envio } from "../domain/entities/envio";

export class EnvioByIdUseCase {
  constructor(private readonly envioRepository: IEnvioRepository) { }
  async run({ id }: { id: string }): Promise<Envio | null> {
    const result = await this.envioRepository.findById(id)
    return result
  }
}
