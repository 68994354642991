import authMiddleware from '@/middleware/auth.middleware'
import userEnterRoute from '@/middleware/checkEnterRoute.middleware'

export default [
  {
    path: '/redes',
    name: 'redes',
    component: ()=>import('./listar'),
    meta:{
      title: 'REDES',
      permiso:'redes_acceso'
    },
    beforeEnter: [authMiddleware,userEnterRoute],
  },
  {
    path: '/redes/form/:id?',
    name: 'redes.form',
    component: ()=>import('./formulario'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'FORMULARIO'
    }
  },
  {
    path: '/redes/:id?/zonas',
    name: 'redes.zonas',
    component: ()=>import('./zonas'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'ZONAS'
    }
  },
  {
    path: '/redes/:id?/rutas',
    name: 'redes.rutas',
    component: ()=>import('./rutas'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'RUTAS'
    }
  },
  {
    path: '/redes/:id?/maestros',
    name: 'redes.maestros',
    component: ()=>import('./maestros'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'MAESTROS'
    }
  },
  {
    path: '/redes/:id?/variables',
    name: 'redes.variables',
    component: ()=>import('./variables'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'VARIABLES'
    }
  },
  {
    path: '/redes/:id?/servicios',
    name: 'redes.servicios',
    component: ()=>import('./servicios'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'SERVICIOS'
    }
  },

]