type TRaw = {
  id: string;
  nombre: string;
  imagen: string;
  capacidad: number;
}

export type TBody = {
  id: string;
  nombre: string;
  imagen: string;
  capacidad: number;
}

export class SerVehiculo {
  public id: string
  public nombre: string
  public imagen: string
  public capacidad: number


  constructor({ id, nombre, imagen, capacidad }: TBody) {
    this.id = id
    this.nombre = nombre
    this.imagen = imagen
    this.capacidad = capacidad
  }
  static toDomain({
    capacidad,
    id,
    imagen,
    nombre,
  }: TRaw): SerVehiculo {
    return new SerVehiculo({
      capacidad,
      id,
      imagen,
      nombre
    })
  }
}
