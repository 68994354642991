import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import store from '../store'

export default async (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (!store.getters['auth/check']) return next({ name: 'auth.login' })
  return next()
}
