import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "w-full flex justify-center" }
const _hoisted_2 = { class: "container p-2 lg:p-0" }
const _hoisted_3 = { class: "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-6" }

import Page from "@/components/Page.vue";
import canHelper from "@/helpers/can.helper";
import { useStore } from "vuex";
import Dialog from 'primevue/dialog'
import {computed, onMounted, ref} from 'vue'
import { canSeeProductosBanner } from "@/const/banners";

type TRol = number;

type TAuthUser ={
  id: string;
  nombres: string;
  roles: TRol[];
}


export default /*@__PURE__*/_defineComponent({
  ...{
  name: 'InicioPage'
},
  __name: 'index',
  setup(__props) {



const store = useStore();
const currentUser:TAuthUser = store.getters["auth/user"];

const isVisibleBanner = ref(false)

const hasRol = () => {
  const findRol = currentUser.roles.some((a) => a === 2);
  return findRol;
};

const rolAuth = computed(()=>{
  const rol = currentUser.roles[0]
  return rol
})

onMounted(() => {
  if (rolAuth.value === 2) {
    const isVisible: string | null = window.localStorage.getItem(canSeeProductosBanner)
    if (isVisible) {
      isVisibleBanner.value = false
    } else {
      window.localStorage.setItem(canSeeProductosBanner, "false")
      isVisibleBanner.value = true
    }
  }
})



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(Page, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "flex justify-center my-5" }, [
            _createElementVNode("h1", null, "Menu Principal")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            (_unref(canHelper)('dashboard_acceso'))
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: { name: 'dashboard' },
                  class: "card shadow flex flex-col justify-between"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createElementVNode("img", {
                      src: "/img/graficos/dashboard_icono.svg",
                      alt: "",
                      class: "mx-auto"
                    }, null, -1),
                    _createElementVNode("p", { class: "w-full titulo-menu font-light" }, "Dashboard", -1)
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(canHelper)('trafico_acceso'))
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: { name: 'trafico' },
                  class: "card shadow flex flex-col justify-between"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createElementVNode("img", {
                      src: "/img/graficos/trafico_icono.svg",
                      alt: "",
                      class: "mx-auto"
                    }, null, -1),
                    _createElementVNode("p", { class: "w-full titulo-menu font-light" }, "Tráfico", -1)
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(canHelper)('mis_datos_acceso') && hasRol())
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 2,
                  to: {
                name: 'clientes.form',
                params: { id: _unref(currentUser).cliente.id },
              },
                  class: "card shadow flex flex-col justify-between"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createElementVNode("img", {
                      src: "/img/graficos/icon_mis_datos.svg",
                      alt: "",
                      class: "mx-auto"
                    }, null, -1),
                    _createElementVNode("p", { class: "w-full titulo-menu font-light" }, "Mis Datos", -1)
                  ])),
                  _: 1
                }, 8, ["to"]))
              : _createCommentVNode("", true),
            (_unref(canHelper)('clientes_acceso'))
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 3,
                  to: { name: 'clientes' },
                  class: "card shadow flex flex-col justify-between"
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createElementVNode("img", {
                      src: "/img/graficos/clientes_icono.svg",
                      alt: "",
                      class: "mx-auto"
                    }, null, -1),
                    _createElementVNode("p", { class: "w-full titulo-menu font-light" }, "Clientes", -1)
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(canHelper)('tarifas_acceso'))
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 4,
                  to: { name: 'tarifas' },
                  class: "card shadow flex flex-col justify-between"
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createElementVNode("img", {
                      src: "/img/graficos/tarifas_icono.svg",
                      alt: "",
                      class: "mx-auto"
                    }, null, -1),
                    _createElementVNode("p", { class: "w-full titulo-menu font-light" }, "Tarifas", -1)
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(canHelper)('facturar_envios_acceso'))
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 5,
                  to: { name: 'facturarEnvios' },
                  class: "card shadow flex flex-col justify-between"
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createElementVNode("img", {
                      src: "/img/graficos/facturar_envios_icono.svg",
                      alt: "",
                      class: "mx-auto"
                    }, null, -1),
                    _createElementVNode("p", { class: "w-full titulo-menu font-light" }, "Facturar Envíos", -1)
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(canHelper)('gestion_facturas_acceso'))
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 6,
                  class: "card shadow flex flex-col justify-between",
                  to: { name: 'gestionFacturas' }
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createElementVNode("img", {
                      src: "/img/graficos/gestion_facturas_icono.svg",
                      alt: "",
                      class: "mx-auto"
                    }, null, -1),
                    _createElementVNode("p", { class: "w-full titulo-menu font-light" }, "Gestión de Facturas", -1)
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(canHelper)('usuarios_acceso'))
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 7,
                  class: "card shadow flex flex-col justify-between",
                  to: { name: 'usuarios' }
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createElementVNode("img", {
                      src: "/img/graficos/usuarios_icono.svg",
                      alt: "",
                      class: "mx-auto"
                    }, null, -1),
                    _createElementVNode("p", { class: "w-full titulo-menu font-light" }, "Usuarios", -1)
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(canHelper)('blog_acceso'))
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 8,
                  to: { name: 'blog' },
                  class: "card shadow flex flex-col justify-between"
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createElementVNode("img", {
                      src: "/img/graficos/blog_icono.svg",
                      alt: "",
                      class: "mx-auto"
                    }, null, -1),
                    _createElementVNode("p", { class: "w-full titulo-menu font-light" }, "Blog", -1)
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(canHelper)('redes_acceso'))
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 9,
                  to: { name: 'redes' },
                  class: "card shadow flex flex-col justify-between"
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createElementVNode("img", {
                      src: "/img/graficos/redes_icono.svg",
                      alt: "",
                      class: "mx-auto"
                    }, null, -1),
                    _createElementVNode("p", { class: "w-full titulo-menu font-light" }, "Redes", -1)
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(canHelper)('almacen_acceso'))
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 10,
                  to: { name: 'warehouse' },
                  class: "card shadow flex flex-col justify-between"
                }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createElementVNode("img", {
                      src: "/img/graficos/embalaje_icono.svg",
                      alt: "",
                      class: "mx-auto"
                    }, null, -1),
                    _createElementVNode("p", { class: "w-full titulo-menu font-light" }, "Warehouse", -1)
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(canHelper)('configuracion_acceso'))
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 11,
                  to: { name: 'configuracion' },
                  class: "card shadow flex flex-col justify-between"
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createElementVNode("img", {
                      src: "/img/graficos/ajustes_icono.svg",
                      alt: "",
                      class: "mx-auto"
                    }, null, -1),
                    _createElementVNode("p", { class: "w-full titulo-menu font-light" }, "Ajustes", -1)
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createVNode(_unref(Dialog), {
        visible: isVisibleBanner.value,
        "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((isVisibleBanner).value = $event)),
        modal: ""
      }, {
        default: _withCtx(() => _cache[14] || (_cache[14] = [
          _createElementVNode("img", {
            src: "/img/banners/b001.jpg",
            alt: "",
            style: {"height":"60vh","width":"100%","object-fit":"contain"}
          }, null, -1)
        ])),
        _: 1
      }, 8, ["visible"])
    ]),
    _: 1
  }))
}
}

})