import { RouteRecordRaw } from 'vue-router'
import { modulo as module } from './const'
import ordenRoutes from './orden/routes'
import datosFacuracionRoutes from './DatosFacturacion/routes'
import authMiddleware from '@/middleware/auth.middleware'
import onlyRolMiddleware from '@/middleware/onlyRolMiddleware'

const routes: RouteRecordRaw[] = [
  {
    path: `/${module}`,
    name: module,
    redirect: `${module}/productos`,
    beforeEnter: [authMiddleware,onlyRolMiddleware],
    meta: {
      rolesAllowed: [2],
    },
    component: () => import('./index.vue'),
    children: [
      {
        path: `inventario`,
        name: `${module}.inventario.listar`,
        component: () => import('./inventario/listar/index.vue')
      },
      {
        path: `inventario/producto/:proId`,
        name: `${module}.inventario.producto.ver`,
        component: () => import('./inventario/productoDetalles/index.vue')
      },
      ...ordenRoutes,
      {
        path: `productos/formulario/:proId?`,
        name: `${module}.productos.form`,
        component: () => import('./productos/productoForm'),
      },
      {
        path: `productos`,
        name: `${module}.productos`,
        component: () => import('./productos'),
      },
      ...datosFacuracionRoutes,
    ]
  }
]

export default routes
