import authMiddleware from '@/middleware/auth.middleware'
import userEnterRoute from '@/middleware/checkEnterRoute.middleware'
export default [
  {
    path: '/facturar-envios',
    name: 'facturarEnvios',
    component: ()=>import('./listar'),
    meta:{
      title: "FACTURAR ENVÍOS",
      permiso:'facturar_envios_acceso'
    },
    beforeEnter: [authMiddleware,userEnterRoute]
  }
]