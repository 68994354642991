//import authMiddleware from '@/middleware/auth.middleware'
//import userEnterRoute from '@/middleware/checkEnterRoute.middleware'

export default [
  /* {
    path: '/trafico/envio/form',
    name: 'trafico.envio.form',
    beforeEnter: [authMiddleware],
    component: ()=> import('./envioForm/index.vue')
  }, */
  /* {
    path: '/trafico/tabs/envios/form/:envioId?',
    name: 'trafico.tab.envios.form',
    component: ()=>import('./tabs/envios/form/index.vue')
  }, */
  /* {
    path: '/trafico/tabs',
    component: ()=>import('./tabs/indexenvios.listar.vue'),
    beforeEnter: [authMiddleware],
    children:[
      {
        path: 'recogidas/form/:id?',
        name: 'trafico.tab.recogidas.form',
        // @ts-ignore
        component: ()=>import('./tabs/recogidas/form/index.vue'),
        meta:{
          title: 'ENVIOS FORMULARIO'
        }
      },
    ]
  }, */
 /*  {
    path: '/envios',
    name: 'envios.listar',
    component: ()=>import('./listar'),
    meta:{
      title: 'ENVIOS',
      permiso:'trafico_acceso'
    },
    beforeEnter: [authMiddleware,userEnterRoute],
  }, */

]
