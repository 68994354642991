import guestMiddleware from '@/middleware/guest.middleware';

export default [
  {
    path: '/auth/login',
    name: 'auth.login',
    beforeEnter: [guestMiddleware],
    component: ()=>import('./login')
  }
]
