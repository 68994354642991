import dayjs from 'dayjs'
import 'dayjs/locale/es'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.locale('es')

const formatterFecha = (value,format="DD MMM YYYY HH:mm") => {
  if(!value) return '--/--/---- --:--'
  //return dayjs(value).tz('Europe/Madrid').format(format)
  return dayjs(value).format(format)
}

export {
  formatterFecha
}
