import authMiddleware from '@/middleware/auth.middleware'
import userEnterRoute from '@/middleware/checkEnterRoute.middleware'
export default [
  {
    path: '/tarifas',
    name: 'tarifas',
    component: () => import('./listar/index.vue'),
    meta:{
      title: "TARIFAS",
      permiso:'tarifas_acceso'
    },
    beforeEnter: [authMiddleware,userEnterRoute]
  },
  {
    path: '/tarifas/:id',
    name: 'tarifas.ver',
    component: () => import('./verEditar/index.vue'),
    meta:{
      title: "EDITAR TARIFA"
    },
    beforeEnter: [authMiddleware,userEnterRoute]
  },
]
