import {useStore} from '../store'

/**
 * @param {string} permiso
 * @returns {boolean}
 */
export default (permiso) => {
  const store = useStore()
  const user  = store.getters['auth/user']
  if(!user) return false
  if(user.permisos.some(a => a === permiso)) return true
  return false
}