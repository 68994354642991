import authMiddleware from '@/middleware/auth.middleware'
import userEnterRoute from '@/middleware/checkEnterRoute.middleware'
export default [
  {
    path: '/clientes/habituales/:id?',
    name: 'clientes.habituales.form',
    component: ()=>import('./habituales/form'),
    meta: {
      title: "Clientes Habituales"
    },
    beforeEnter: [authMiddleware,userEnterRoute]
  },
  {
    path: '/clientes',
    name: 'clientes',
    component: ()=>import('./listar'),
    meta:{
      title:'CLIENTES',
      permiso:'clientes_acceso'
    },
    beforeEnter: [authMiddleware,userEnterRoute]
  },
  {
    path: '/clientes/form/:id?',
    name: 'clientes.form',
    component: ()=>import('./form'),
    meta:{
      title: "Clientes Formulario",
      permiso:'mis_datos_acceso'
    },
    beforeEnter: [authMiddleware,userEnterRoute]
  },
  {
    path: '/clientes/:id?/usuarios',
    name: 'clientes.usuarios',
    component: ()=>import('./usuarios'),
    meta:{
      title: "Clientes Usuarios"
    },
    beforeEnter: [authMiddleware,userEnterRoute]
  },
  {
    path: '/clientes/:id?/habituales',
    name: 'clientes.habituales',
    component: ()=>import('./habituales/listar'),
    meta: {
      title: "Clientes Habituales"
    },
    beforeEnter: [authMiddleware,userEnterRoute]
  },

  {
    path: '/clientes/:id?/sedes',
    name: 'clientes.sedes',
    component: ()=>import('./sedes'),
    meta:{
      title: "Clientes Sedes"
    },
    beforeEnter: [authMiddleware,userEnterRoute]
  },
  {
    path: '/clientes/:id?/redes',
    name: 'clientes.redes',
    component: ()=>import('./redes'),
    meta:{
      title: "Clientes Redes"
    },
    beforeEnter: [authMiddleware,userEnterRoute]
  },

]
