import axios from '@/libs/axios.lib'
import { host } from '@/config/trafico.config'
import { IEnvioRepository, TCotizarResponse, TRegistrar, TRegistrarResponse, TUpdateBody } from "../../domain/contracts/iEnvioRepository";
import { AxiosError } from 'axios';
import { EnvioException } from '../../domain/exceptions/envioException';
import { EnvioTabsContar } from '../../domain/entities/envioTabsContar';
import { Envio } from '../../domain/entities/envio';
import { Paginate } from '@/boundedContext/shared/domain/entities/paginate';

export class EnvioRepository implements IEnvioRepository {
  async updateById(body: TUpdateBody): Promise<void> {
    try {
      await axios.put(`${host}/v2/envios/${body.envioId}/update`,{
        ...body,
      })
    } catch (error) {
      const err = error as AxiosError
      const data = err.response?.data as { message: string }
      throw new EnvioException(data.message ?? 'No fue posible actualizar')
    }
  }
  async registrar(body: TRegistrar): Promise<TRegistrarResponse> {
    const {data} = await axios.post(`${host}/v2/envios/registrar`,{
      ...body,
    })
    return data
  }
  async cotizar({ vehiculoId, clienteId, redSerId, kgsCant, ayudanteCant, bultoCant, origenLatLng, destinoLatLng }: { vehiculoId: string; clienteId: string; kgsCant: number; redSerId: number; ayudanteCant: number; bultoCant: number; origenLatLng: string; destinoLatLng: string; }): Promise<TCotizarResponse> {
    const {data} = await axios.post(`${host}/v2/envios/cotizar`,{
      vehiculoId,
      clienteId,
      kgsCant,
      redSerId,
      ayudanteCant,
      bultoCant,
      origenLatLng,
      destinoLatLng,
    })
    return {
      id: data.id,
      total: data.total,
      iva: data.iva,
      subTotal: data.subTotal,
      variables: data.variables,
      envioCosto: data.envioCosto,
    }
  }
  async estadoCambiar({ envioId, estado, justificacion }: { envioId: string; estado: string; justificacion:string | null }): Promise<void> {
    await axios.post(`${host}/v2/envios/estados`,{ envioId, estado, justificacion })
  }
  async mensajeroAsociar({ envioId, mensajeroId }: { envioId: string; mensajeroId: string; }): Promise<void> {
    await axios.post(`${host}/v2/envios/mensajero`,{
      envioId,
      mensajeroId
    })
  }
  async getUrlPdf({ ids, token }: { ids: string; token: string; }): Promise<string> {
    const result = axios.getUri({
      url: `${host}/v2/envios/pdf`,
      params:{
        ids,
        token
      }
    })
    return result
  }
  async findAll({
    fechaDesde,
    fechaHasta,
    limit,
    page,
    estado,
    clienteIds,
    servicioIds,
  }: {
    fechaDesde: string;
    fechaHasta: string;
    limit: number;
    page: number;
    estado: string;
    clienteIds?:string;
    servicioIds?:string
  }): Promise<Paginate<Envio>> {
    const {data} = await axios.get(`${host}/v2/envios`,{
      params:{
        fechaDesde: fechaDesde,
        fechaHasta: fechaHasta,
        limit: limit,
        page: page,
        estado: estado,
        clienteIds: clienteIds,
        servicioIds: servicioIds,
      }
    })
    return new Paginate({
      offset: data.offset,
      data: data.data,
      lastPage: data.lastPage,
      limit: data.limit,
      page: data.page,
      total: data.total,
    })
  }

  async findTabsContar({ fechaDesde, fechaHasta, clienteIds, servicioIds }: { fechaDesde: string; fechaHasta: string; clienteIds?: string | undefined; servicioIds?: string | undefined; }): Promise<EnvioTabsContar> {
    const { data } = await axios.get(`${host}/v2/envios/estados/info`,{
      params:{
        fechaDesde: fechaDesde,
        fechaHasta: fechaHasta,
        clienteIds: clienteIds,
        servicioIds: servicioIds,
      }
    })
    return EnvioTabsContar.toDomain(data)
  }

  async findById(id: string): Promise<Envio | null> {
    try {
      const {data} = await axios.get(`${host}/v2/envios/${id}`)
      const envioDomain = Envio.toDomain(data)
      return envioDomain
    } catch (error) {
      console.error(error);
      return null
    }
  }
}
