import { IEnvioTabsContar } from "./iEnvioTabsContar";

export type TRaw = {
  preparacion: number;
  recogida: number;
  entregado: number;
  incidencia: number;
  reparto: number;
  anulado: number;
}

export type TBody ={
  preparacion: number;
  recogida: number;
  entregado: number;
  incidencia: number;
  reparto: number;
  anulado: number;
}
export class EnvioTabsContar implements IEnvioTabsContar {
  preparacion: number;
  recogida: number;
  entregado: number;
  incidencia: number;
  reparto: number;
  anulado: number;

  constructor(body: TBody){
    this.anulado = body.anulado
    this.recogida = body.recogida
    this.preparacion = body.preparacion
    this.entregado = body.entregado
    this.incidencia = body.incidencia
    this.reparto = body.reparto
  }


  static toDomain(raw: TRaw): EnvioTabsContar {
    return new EnvioTabsContar({
      entregado: raw.entregado,
      incidencia: raw.incidencia,
      recogida: raw.recogida,
      reparto: raw.reparto,
      anulado: raw.anulado,
      preparacion: raw.preparacion,
    })
  }

}
