import authMiddleware from '@/middleware/auth.middleware'
export default [
    {
        path:'/dashboard',
        name:'dashboard',
        component:()=>import('./listar'),
        meta:{
            title:"DASHBOARD",
            // permiso:'dashboard_acceso'
        },
        beforeEnter:[authMiddleware]
    }
]