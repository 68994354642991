import authMeService from '@/views/auth/services/authMe.service'
import authRefreshTokenService from '@/views/auth/services/authRefreshToken.service'

const initialToken = window.localStorage.getItem('JWT_TOKEN')

const state = () => {
  return {
    user: null,
    token: initialToken,
    refreshTask: 0
  }
}

// getters
const getters = {
  token: state => state.token,
  user: state => state.user,
  check: state => state.user !== null

}

// actions
const actions = {
  saveToken ({ commit}, payload) {
    commit('SAVE_TOKEN', payload)
  },
  async fetchUser ({ commit }) {
    try {
      const { data } = await authMeService()
      commit('FETCH_USER_SUCCESS', { user: data })
    } catch (e) {
      console.error(e);
      commit('FETCH_USER_FAILURE')
    }
  },
  async logout ({ commit }) {
    try {
      // await authLogoutService()
    } catch (e) {
      console.error(e)
    }

    commit('LOGOUT')
  },
  refreshTokens({commit,state,dispatch}){
    const {refreshToken} = state
    authRefreshTokenService({ refreshToken }).then(({data})=>{
      const {accessToken: token, refreshToken} = data
      commit('SAVE_TOKEN',{ token, refreshToken })
      console.log('token renovado')
      dispatch('autoRefresh')
    }).catch(err=>{
      console.error(err.message)
    })
  },

  /**
   * @deprecated 2024-02-19
   * @author carlos sanchez
   */
  autoRefresh(){

    /* const {token} = state
    const {exp} = jwtDecode(token)
    const now = dayjs(new Date())
    const fechaExp = dayjs( new Date(exp*1000) )
    let timeUntilRefresh = fechaExp.diff(now,'s')
    timeUntilRefresh -= (1 * 60)

    if(timeUntilRefresh <= 0) return false

    const refreshTask = setTimeout(() => dispatch('refreshTokens'),timeUntilRefresh * 1000)
    commit('refreshTask',refreshTask) */
  }

}

// mutations
const mutations = {
  SAVE_TOKEN(state, { token, remember, refreshToken }) {
    state.token = token
    state.refreshToken  = refreshToken

    window.localStorage.setItem('JWT_TOKEN', token)
    window.localStorage.setItem('JWT_EXPIRES', remember)

  },
  LOGOUT (state) {
    state.user = null
    state.token = null

    window.localStorage.removeItem('JWT_TOKEN')
    window.localStorage.removeItem('JWT_EXPIRES')

  },
  FETCH_USER_SUCCESS(state, { user }) {
    const userData = user

    state.user = userData
  },
  FETCH_USER_FAILURE (state) {
    state.token = null
    window.localStorage.removeItem('JWT_TOKEN')
  },
  refreshTask(state,payload){
    if(state.refreshTask) clearInterval(state.refreshTask)
    state.refreshTask = payload
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
