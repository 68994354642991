import store from '../store'

export default (to, from, next) => {
  //console.dir(to)
  //console.dir(from)
  if (store.getters['auth/check']) {
    return next(from.path)
  }
  return next()
}
