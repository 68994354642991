import { IEnvioDestino } from "./iEnvioDestino";
import { Ciudad } from "@/boundedContext/base/domain/domain/entities/ciudad";

export interface ICiudad  {
  id: number;
  name: string;
}
export type TRaw = {
  ciudadId: number;
  codigoPostal: string;
  complemento: string;
  correo: string;
  direccion: string;
  dni: string | null;
  latitud: number;
  longitud: number;
  movil: string;
  nombre: string;
  ciudad: ICiudad;
}
export type TBody = {
  direccion: string;
  codigoPostal: string;
  nombre: string;
  movil: string;
  correo: string;
  ciudad: Ciudad;
  ciudadId: number;
  dni: string | null;
  latitud: number | null;
  longitud: number | null;
  complemento: string;
}

export class EnvioDestino implements IEnvioDestino {
  direccion: string;
  codigoPostal: string;
  nombre: string;
  movil: string;
  correo: string;
  ciudadId: number;
  dni: string | null;
  latitud: number | null;
  longitud: number | null;
  complemento: string;
  ciudad: Ciudad;

  constructor({
    direccion,
    codigoPostal,
    nombre,
    movil,
    ciudad,
    ciudadId,
    correo,
    dni,
    latitud,
    longitud,
    complemento,
  }: TBody) {
    this.codigoPostal = codigoPostal
    this.direccion = direccion
    this.nombre = nombre
    this.movil = movil
    this.correo = correo
    this.dni = dni
    this.ciudadId = ciudadId
    this.ciudad = ciudad
    this.latitud = latitud
    this.longitud = longitud
    this.complemento = complemento
    this.ciudad = ciudad
  }

  static toDomain({
    ciudad,
    ciudadId,
    codigoPostal,
    complemento,
    correo,
    direccion,
    dni,
    latitud,
    longitud,
    movil,
    nombre,
  }: TRaw):EnvioDestino{
    return new EnvioDestino({
      ciudadId,
      codigoPostal,
      complemento,
      correo,
      direccion,
      dni,
      latitud,
      longitud,
      movil,
      nombre,
      ciudad: Ciudad.toDomain({
        id: ciudad.id,
        nombre: ciudad.name,
      }),
    })
  }
}
