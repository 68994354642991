import authMiddleware from '@/middleware/auth.middleware'
import userEnterRoute from '@/middleware/checkEnterRoute.middleware'

export default [
  {
    path: '/usuarios/form/:id?',
    name: 'usuarios.form',
    component: ()=>import('./form'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'USUARIOS FORMULARIO'
    }
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: ()=>import('./listar'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'USUARIOS',
      permiso:'usuarios_acceso'
    }
  },
  {
    path: '/usuarios/:id?/permisos',
    name: 'usuarios.permisos',
    component: ()=>import('./permisos'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'USUARIOS PERMISOS'
    }
  },
]