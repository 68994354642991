import configuracionBancos from './bancos/routes'
import configuracionUbicaciones from './ubicaciones/routes'
import configuracionImpuestos from './impuestos/routes'
import configuracionDependencias from './dependencias/routes'
import configuracionEmpresa from "./empresa/routes"
import configuracionRoles from "./roles/routes"
import authMiddleware from '@/middleware/auth.middleware'
import userEnterRoute from '@/middleware/checkEnterRoute.middleware'
import categoriasRoutes from './categorias/routes'
import variablesRoutes from './variables/routes'

export default [
  {
    path: '/configuracion',
    name: 'configuracion',
    component: ()=>import('./main'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'CONFIGURACION',
      permiso:'configuracion_acceso'
    }
  },
  ...categoriasRoutes,
  ...configuracionBancos,
  ...configuracionUbicaciones,
  ...configuracionImpuestos,
  ...configuracionDependencias,
  ...configuracionEmpresa,
  ...configuracionRoles,
  ...variablesRoutes
]
