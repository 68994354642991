import authMiddleware from '@/middleware/auth.middleware'
import userEnterRoute from '@/middleware/checkEnterRoute.middleware'
export default [
  {
    path: '/configuracion/bancos',
    name: 'configuracion.bancos',
    component: ()=>import('./listar'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'BANCOS',
      permiso:'configuracion_bancos_acceso'
    }
  },

  // {
  //   path: '/configuracion/bancos/crear',
  //   name: 'configuracion.bancos.crear',
  //   component: ()=>import('./crear'),
  //   beforeEnter: [authMiddleware,userEnterRoute],
  //   meta:{
  //     title: 'BANCOS FORMULARIO',
  //     permiso:'configuracion_banco_crear_acceso'
  //   }
  // },
  // {
  //   path: '/configuracion/bancos/editar/:id',
  //   name: 'configuracion.bancos.editar',
  //   component: ()=>import('./editar'),
  //   beforeEnter: [authMiddleware,userEnterRoute],
  //   meta:{
  //     title: 'BANCOS FORMULARIO',
  //     permiso:'configuracion_banco_editar_acceso'
  //   }
  // }
]