import authMiddleware from '@/middleware/auth.middleware'
import userEnterRoute from '@/middleware/checkEnterRoute.middleware'
export default [
  {
    path: '/configuracion/impuestos',
    name: 'configuracion.impuestos',
    component: ()=>import('./listar'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'IMPUESTOS',
      permiso:'configuracion_impuesto_acceso'
    }
  },
  
]