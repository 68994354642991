<template>
  <Navbar />
  <slot />
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import {useStore} from 'vuex'
import { onMounted, onBeforeMount, onBeforeUnmount } from 'vue'
import eventBus from '@/eventBus'

export default {
  name: 'PageComponent',
  components:{
    Navbar
  },
  setup() {
    const store = useStore()
    onBeforeMount(()=>{
      //console.log('page onBeforeMount');
      eventBus.$on('algo-si',(evt)=>{
        console.log('algo-si::::',evt);
      })
    })
    onMounted(()=>{
      store.dispatch('auth/autoRefresh')
    })
    onBeforeUnmount(()=>{
      eventBus.$off("algo-si")
      //console.log('page onBeforeMount');
    })

    return { }
  },
}
</script>
