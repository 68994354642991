import onlyRolMiddleware from '@/middleware/onlyRolMiddleware'
import authMiddleware from '@/middleware/auth.middleware'

export default [
  {
    path: '/configuracion/variables/listar',
    name: 'configuracion.variables.listar',
    beforeEnter: [authMiddleware,onlyRolMiddleware],
    meta:{
      rolesAllowed: [1]
    },
    component: ()=>import('./listar/index.vue')
  }
]
