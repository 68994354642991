import authMiddleware from '@/middleware/auth.middleware'
import userEnterRoute from '@/middleware/checkEnterRoute.middleware'
export default [
  {
    path: '/configuracion/empresas',
    name: 'configuracion.empresas.listar',
    component: ()=>import('./listar'),
    beforeEnter: [authMiddleware],
    meta:{
      title: 'EMPRESAS'
    }
  },
  {
    path: '/configuracion/empresa/:id?',
    name: 'configuracion.empresa.form',
    component: ()=>import('./form'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'FORMULARIO',
      permiso:'configuracion_empresa_acceso'
    }
  },
  {
    path: '/configuracion/empresa/:id?/bancos',
    name: 'configuracion.empresa.bancos',
    component: ()=>import('./bancosListar'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'BANCOS-EMPRESA',
      // permiso:'configuracion_empresa_bancos_acceso'
    }
  },

]
