import authMiddleware from '@/middleware/auth.middleware'
import onlyRolMiddleware from '@/middleware/onlyRolMiddleware'
import { RouteRecordRaw } from 'vue-router'

const router: RouteRecordRaw[] = [
  {
    path: '/trafico/envio/:envioId/editar',
    name: 'trafico.envio.editar',
    beforeEnter: [authMiddleware, onlyRolMiddleware],
    meta: {
      rolesAllowed: [1,4,7],
    },
    component: () => import('../editar/index.vue'),
  },
  {
    path: '/trafico/envio',
    name: 'trafico.envio',
    beforeEnter: [authMiddleware],
    component: () => import('../envioForm/index.vue'),
  },
  {
    path: '/trafico',
    name: 'trafico',
    beforeEnter: [authMiddleware],
    component: () => import('../listar/index.vue')
  },
]

export default router
