export type TRaw = {
  id: string;
  envioId: string;
  total: number;
  iva: number;
  subtotal: number;
  envioCosto: number;
}
export type TBody = {
  id: string;
  envioId: string;
  total: number;
  iva: number;
  subtotal: number;
  envioCosto: number;
}

export class EnvioCotizacion {
  id: string;
  envioId: string;
  total: number;
  iva: number;
  subtotal: number;
  envioCosto: number;

  constructor(body:TBody){
    this.id = body.id
    this.envioId = body.envioId
    this.total = +body.total
    this.iva = +body.iva
    this.subtotal = +body.subtotal
    this.envioCosto = +body.envioCosto
  }

  static toDomain(rawData:TRaw){
    return new EnvioCotizacion({
      ...rawData,
      total: +rawData.total,
      subtotal: +rawData.subtotal,
      iva: +rawData.iva,
      envioCosto: +rawData.envioCosto
    })
  }
}
