import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "caja h-screen flex items-center justify-center" }


export default /*@__PURE__*/_defineComponent({
  ...{
  name: 'error403Page'
},
  __name: 'index',
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, " 403 No autorizado! "))
}
}

})