import { RouteRecordRaw } from "vue-router";
import { modulo } from '../const'
import authMiddleware from '@/middleware/auth.middleware'

const routes: RouteRecordRaw[] = [
  {
    path: `inventario/ordenes/listar`,
    name: `${modulo}.ordenes.listar`,
    beforeEnter: [authMiddleware],
    component: () => import('./listar/index')
  },
  {
    path: `inventario/ordenes/formulario`,
    name: `${modulo}.ordenes.formulario`,
    beforeEnter: [authMiddleware],
    component: () => import('./ordenForm/index')
  },
  {
    path: `inventario/orden/:ordenId`,
    name: `${modulo}.ordenes.verDetalle`,
    beforeEnter: [authMiddleware],
    component: () => import('./verDetalles/index')
  },
]

export default routes
