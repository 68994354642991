<template>
  <Page>
    <div class="w-full flex justify-center">
      <div class="container p-2 lg:p-0">
        <div class="flex justify-center my-5">
          <h1>Menu Principal</h1>
        </div>
        <div
          class="
            grid grid-cols-2
            md:grid-cols-3
            lg:grid-cols-3
            xl:grid-cols-4
            gap-4
            mb-6
          "
        >
          <template v-if="canHelper('dashboard_acceso')">
            <router-link
              :to="{ name: 'dashboard' }"
              class="card shadow flex flex-col justify-between"
            >
              <img
                src="/img/graficos/dashboard_icono.svg"
                alt=""
                class="mx-auto"
              />
              <p class="w-full titulo-menu font-light">Dashboard</p>
            </router-link>
          </template>

          <template v-if="canHelper('trafico_acceso')">
            <router-link
              :to="{ name: 'trafico' }"
              class="card shadow flex flex-col justify-between"
            >
              <img
                src="/img/graficos/trafico_icono.svg"
                alt=""
                class="mx-auto"
              />
              <!-- <h2 class="w-full">Tráfico</h2> -->
              <p class="w-full titulo-menu font-light">Tráfico</p>
            </router-link>
          </template>

          <template v-if="canHelper('mis_datos_acceso') && hasRol()">
            <router-link
              :to="{
                name: 'clientes.form',
                params: { id: currentUser.cliente.id },
              }"
              class="card shadow flex flex-col justify-between"
            >
              <img
                src="/img/graficos/icon_mis_datos.svg"
                alt=""
                class="mx-auto"
              />
              <p class="w-full titulo-menu font-light">Mis Datos</p>
            </router-link>
          </template>


          <template v-if="canHelper('clientes_acceso')">
            <router-link
              :to="{ name: 'clientes' }"
              class="card shadow flex flex-col justify-between"
            >
              <img
                src="/img/graficos/clientes_icono.svg"
                alt=""
                class="mx-auto"
              />
              <p class="w-full titulo-menu font-light">Clientes</p>
            </router-link>
          </template>
          <template v-if="canHelper('tarifas_acceso')">
            <router-link
              :to="{ name: 'tarifas' }"
              class="card shadow flex flex-col justify-between"
            >
              <img
                src="/img/graficos/tarifas_icono.svg"
                alt=""
                class="mx-auto"
              />
              <p class="w-full titulo-menu font-light">Tarifas</p>
            </router-link>
          </template>
          <template v-if="canHelper('facturar_envios_acceso')">
            <router-link
              :to="{ name: 'facturarEnvios' }"
              class="card shadow flex flex-col justify-between"
            >
              <img
                src="/img/graficos/facturar_envios_icono.svg"
                alt=""
                class="mx-auto"
              />
              <p class="w-full titulo-menu font-light">Facturar Envíos</p>
            </router-link>
          </template>
          <template v-if="canHelper('gestion_facturas_acceso')">
            <router-link
              class="card shadow flex flex-col justify-between"
              :to="{ name: 'gestionFacturas' }"
            >
              <img
                src="/img/graficos/gestion_facturas_icono.svg"
                alt=""
                class="mx-auto"
              />
              <p class="w-full titulo-menu font-light">Gestión de Facturas</p>
            </router-link>
          </template>
          <template v-if="canHelper('usuarios_acceso')">
            <router-link
              class="card shadow flex flex-col justify-between"
              :to="{ name: 'usuarios' }"
            >
              <img
                src="/img/graficos/usuarios_icono.svg"
                alt=""
                class="mx-auto"
              />
              <p class="w-full titulo-menu font-light">Usuarios</p>
            </router-link>
          </template>
          <template v-if="canHelper('blog_acceso')">
            <router-link
              :to="{ name: 'blog' }"
              class="card shadow flex flex-col justify-between"
            >
              <img src="/img/graficos/blog_icono.svg" alt="" class="mx-auto" />
              <p class="w-full titulo-menu font-light">Blog</p>
            </router-link>
          </template>
          <template v-if="canHelper('redes_acceso')">
            <router-link
              :to="{ name: 'redes' }"
              class="card shadow flex flex-col justify-between"
            >
              <img src="/img/graficos/redes_icono.svg" alt="" class="mx-auto" />
              <p class="w-full titulo-menu font-light">Redes</p>
            </router-link>
          </template>
          <template v-if="canHelper('almacen_acceso')">
            <router-link
              :to="{ name: 'warehouse' }"
              class="card shadow flex flex-col justify-between"
            >
              <img src="/img/graficos/embalaje_icono.svg" alt="" class="mx-auto" />
              <p class="w-full titulo-menu font-light">Warehouse</p>
            </router-link>
          </template>
          <template v-if="canHelper('configuracion_acceso')">
            <router-link
              :to="{ name: 'configuracion' }"
              class="card shadow flex flex-col justify-between"
            >
              <img
                src="/img/graficos/ajustes_icono.svg"
                alt=""
                class="mx-auto"
              />
              <p class="w-full titulo-menu font-light">Ajustes</p>
            </router-link>
          </template>
        </div>
      </div>
    </div>
    <Dialog
    v-model:visible="isVisibleBanner"
    modal
    >
      <img src="/img/banners/b001.jpg" alt="" style="height: 60vh; width: 100%; object-fit: contain;">
    </Dialog>
  </Page>
</template>
<script setup lang="ts">
import Page from "@/components/Page.vue";
import canHelper from "@/helpers/can.helper";
import { useStore } from "vuex";
import Dialog from 'primevue/dialog'
import {computed, onMounted, ref} from 'vue'
import { canSeeProductosBanner } from "@/const/banners";

type TRol = number;

type TAuthUser ={
  id: string;
  nombres: string;
  roles: TRol[];
}

defineOptions({
  name: 'InicioPage'
})

const store = useStore();
const currentUser:TAuthUser = store.getters["auth/user"];

const isVisibleBanner = ref(false)

const hasRol = () => {
  const findRol = currentUser.roles.some((a) => a === 2);
  return findRol;
};

const rolAuth = computed(()=>{
  const rol = currentUser.roles[0]
  return rol
})

onMounted(() => {
  if (rolAuth.value === 2) {
    const isVisible: string | null = window.localStorage.getItem(canSeeProductosBanner)
    if (isVisible) {
      isVisibleBanner.value = false
    } else {
      window.localStorage.setItem(canSeeProductosBanner, "false")
      isVisibleBanner.value = true
    }
  }
})


</script>
<style lang="scss" scoped>
.titulo-menu {
  color: #0b7285;
  font-size: 30px !important;
}
.card {
  background-color: #c5f6fa33;

  h2 {
    height: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
  }

  img {
    height: 100px;
    width: 100px;
  }
}
</style>
