export default [
  {
    path: '/payment/envio',
    component: ()=>import('../payment/envio')
  },
  {
    path: '/payment/success',
    component: () => import('../payment/success')
  },
  {
    path: '/payment/cancel',
    component: () => import('../payment/cancel')
  },
]
