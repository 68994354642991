import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { useStore } from '../store'

type TMetaRol = {
  rolesAllowed: number[]
}

export default (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const meta: TMetaRol = to.meta as TMetaRol
  const store = useStore()
  if (meta.rolesAllowed) {
    const userRol = store.getters['auth/user'].roles[0]
    console.log(meta.rolesAllowed);
    if (meta.rolesAllowed.includes(userRol)) return next()
    return next({ name: 'noAutorizado' })
  }
  next()
}
