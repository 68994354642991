import authMiddleware from '@/middleware/auth.middleware'
import userEnterRoute from '@/middleware/checkEnterRoute.middleware'
export default [
  {
    path: '/configuracion/roles',
    name: 'configuracion.roles',
    component: ()=>import('./listar'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'ROLES',
      permiso:'configuracion_roles_acceso'
    }
  },
  {
    path: '/configuracion/rol/:id/permisos',
    name: 'configuracion.roles.permisos',
    component: ()=>import('./permisos'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'ROLES PERMISOS',
      permiso:'configuracion_roles_permisos_acceso'
    }
  },
]
