import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "navbar color-primary shadow w-full flex justify-center text-white" }
const _hoisted_2 = { class: "flex justify-between items-center px-2" }
const _hoisted_3 = {
  id: "ellogo",
  class: "flex items-center"
}
const _hoisted_4 = { class: "hidden lg:block" }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = { class: "flex-col justify-start flex items-start" }
const _hoisted_8 = { class: "font-black capitalize mb-1" }
const _hoisted_9 = { class: "flex flex-col" }
const _hoisted_10 = { class: "font-bold" }
const _hoisted_11 = { class: "text-xs text-right" }

import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import TabMenu from "primevue/tabmenu";
import Sidebar from 'primevue/sidebar';
import {formatterFecha} from "@/helpers/date.helper"
import { canSeeProductosBanner } from "@/const/banners";

type AuthUser = {
  id: string;
  nombres: string;
  permisos: string[];
  roles: number[];
}

const __default__ = {
  name: 'NavBar',
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

const activeTabMenu = ref(0)
const store = useStore();
const router = useRouter();
const isModalOpen = ref(false)

const getUsuario = computed<AuthUser>(() => {
  const user = store.getters["auth/user"];
  if (user) return user;
  return {
    nombres: "",
    permisos: []
  };
});
const onLogout = () => {
  store.dispatch("auth/logout");
  router.push({ name: "auth.login" });

  /**
   * 2024-04-26
   * esto es para controlar que muestre un banner de productos
   * cuando el comercio hace login
   */
  window.localStorage.removeItem(canSeeProductosBanner)
};
const items = computed(()=>{
  const menus = [
    {
      label: 'Inicio',
      url: '/',
      order: 0,
      //command: ()=> router.push('/'),
    }
  ]
  if(getUsuario.value.permisos.some(a => a === 'dashboard_acceso')) {
    menus.push({
      label: "Dashboard",
      url: "/dashboard",
      order: 1,
      //command: ()=> router.push('/dashboard'),
    })
  }

  if(getUsuario.value.permisos.some(a => a === 'trafico_acceso')) {
    menus.push({
      label: "Tráfico",
      url: "/trafico",
      order: 2,
      //command: ()=> router.push('/envios'),
    })
  }

  if(getUsuario.value.permisos.some(a => a === 'clientes_acceso')) {
    menus.push({
      label: "Clientes",
      url: "/clientes",
      order: 3,
      //command: ()=> router.push('/clientes'),
    })
  }

  if(getUsuario.value.permisos.some(a => a === 'tarifas_acceso')) {
    menus.push({
      label: "Tarifas",
      url: "/tarifas",
      order: 4,
      //command: ()=> router.push('/tarifas'),
    })
  }

  if(getUsuario.value.permisos.some(a => a === 'facturar_envios_acceso')) {
    menus.push({
      label: "Facturar Envíos",
      url: "/facturar-envios",
      order: 5,
      //command: ()=> router.push('/facturar-envios'),
    })
  }

  if(getUsuario.value.permisos.some(a => a === 'gestion_facturas_acceso')) {
    menus.push({
      label: "Gestión de Facturas",
      url: "/gestion-facturas",
      order: 6,
      //command: ()=> router.push('/gestion-facturas'),
    })
  }

  if(getUsuario.value.permisos.some(a => a === 'redes_acceso')) {
    menus.push({
      label: "Redes",
      url: "/redes",
      order: 9,
      //command: ()=> router.push('/redes'),
    })
  }

  if(getUsuario.value.permisos.some(a => a === 'mis_datos_acceso')) {
    menus.push({
      label: "Mis Datos",
      url: `/clientes/form/${clienteId.value}`,
      order: 11,
      //command: ()=> router.push(`/clientes/form/${clienteId.value}`),

    })
  }

  return menus.sort((a,b)=> a.order - b.order)
})
const getNotificaciones = computed(()=>store.getters['notify/getListado'])
const openNotify = ()=>{
  store.dispatch('notify/fetchNotify')
  isModalOpen.value = !isModalOpen.value
}
const clienteId = computed(()=>{
  const hasRolCliente = store.getters["auth/user"].roles.some((a:number)=>a===2)
  if (hasRolCliente) return store.getters["auth/user"].cliente.id
  return store.getters["auth/user"].id
})



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createElementVNode("img", {
                class: "w-14 mr-2",
                style: {"height":"75px"},
                src: "/img/logo_navbar.svg",
                alt: ""
              }, null, -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(TabMenu), {
            activeIndex: activeTabMenu.value,
            "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((activeTabMenu).value = $event)),
            model: items.value
          }, null, 8, ["activeIndex", "model"])
        ]),
        _createElementVNode("div", { class: "flex" }, [
          _createElementVNode("button", {
            type: "button",
            class: "text-white mi-btn mi-btn-sm",
            onClick: openNotify
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              height: "32px",
              viewBox: "0 0 24 24",
              width: "32px",
              fill: "white"
            }, [
              _createElementVNode("path", { d: "M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z" })
            ], -1)
          ]))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[4] || (_cache[4] = _createElementVNode("div", {
              class: "bg-blue-900 mr-2 rounded-full",
              style: {"height":"50px","width":"50px"}
            }, null, -1)),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(getUsuario.value.nombres), 1),
              _createElementVNode("button", {
                type: "button",
                class: "mi-btn mi-btn-danger mi-btn-sm",
                title: "Cerrar sesión",
                onClick: onLogout
              }, " Cerrar sesión ")
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_unref(Sidebar), {
      visible: isModalOpen.value,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((isModalOpen).value = $event)),
      position: "right"
    }, {
      default: _withCtx(() => [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "mb-2" }, [
          _createElementVNode("h1", { class: "font-bold uppercase" }, "Centro de notificaciones")
        ], -1)),
        _createElementVNode("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getNotificaciones.value, (row, k) => {
            return (_openBlock(), _createElementBlock("div", { key: k }, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_10, _toDisplayString(row.title), 1),
                _createElementVNode("div", null, _toDisplayString(row.message), 1),
                _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(formatterFecha)(row.createdAt)), 1),
                _cache[5] || (_cache[5] = _createElementVNode("hr", { class: "mb-4" }, null, -1))
              ])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})