import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import PrimeVue from 'primevue/config'


import './assets/styles/tailwind.scss'

import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/tailwind-light/theme.css'
import 'primeicons/primeicons.css'


import './assets/styles/global.scss'


import './libs/socket.lib'
import toastservice from 'primevue/toastservice'

const miApp = createApp(App)
.use(store)
.use(router)
.use(PrimeVue, { ripple: false })
.use(toastservice)

miApp.mount('#app')
