import { CliCateComercio } from "./cliCateComercio";

type TBody = {
  id: string;
  razonSocial: string;
  logo: string;
  contactoTelefono: string;
  facturacionCorreo: string;
  nifcif:string;
  direccion: string;
  isOpen: boolean;
}

export type TClienteRaw = {
  id: string;
  razonSocial: string;
  logo: string;
  contactoTelefono: string;
  facturacionCorreo: string;
  nifcif:string;
  direccion: string;
  isOpen: boolean;
}

export class Cliente {
  #id: string;
  #razonSocial: string;
  #logo: string;
  #contactoTelefono: string;
  #facturacionCorreo: string;
  #nifcif: string;
  #direccion: string;
  #isOpen: boolean;
  #catComercios: CliCateComercio[] = []

  get id() { return this.#id }
  get razonSocial() { return this.#razonSocial }
  get logo() { return this.#logo }
  get contactoTelefono() { return this.#contactoTelefono}
  get facturacionCorreo(){ return this.#facturacionCorreo}
  get nifcif(){ return this.#nifcif }
  get direccion(){ return this.#direccion }
  get isOpen(){ return this.#isOpen }
  get catComercios(){ return this.#catComercios}

  constructor(body: TBody) {
    this.#id = body.id
    this.#razonSocial = body.razonSocial
    this.#logo = body.logo
    this.#contactoTelefono = body.contactoTelefono
    this.#facturacionCorreo = body.facturacionCorreo
    this.#nifcif = body.nifcif
    this.#isOpen = body.isOpen
    this.#direccion = body.direccion
  }

  toDTO() {
    return {
      id: this.#id,
      razonSocial: this.#razonSocial,
      logo: this.#logo,
      contactoTelefono: this.#contactoTelefono,
      facturacionCorreo: this.facturacionCorreo,
      nifcif: this.#nifcif,
      direccion: this.#direccion,
      isOpen: this.#isOpen,
      catComercios: this.#catComercios.map(a=>a.toPlain()),
    }
  }

  static toDomain(body: TClienteRaw) {
    return new Cliente({
      id: body.id,
      razonSocial: body.razonSocial,
      logo: body.logo,
      contactoTelefono: body.contactoTelefono,
      facturacionCorreo: body.facturacionCorreo,
      direccion: body.direccion,
      nifcif: body.nifcif,
      isOpen: body.isOpen,
    })
  }
  addCatCategoria(value:CliCateComercio):void{
    this.#catComercios.push(value)
  }
}
