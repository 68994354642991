export type TRaw = {
  valor: number;
  redVariableId: number;
  cantidad: number;
  nombre: string;
}

type TBody = {
  valor: number;
  redVariableId: number;
  cantidad: number;
  nombre: string;
}
export class EnvioVariable {
  valor: number;
  redVariableId: number;
  cantidad: number;
  nombre: string;

  constructor({
    valor,
    redVariableId,
    cantidad,
    nombre,
  }: TBody) {
    this.redVariableId = redVariableId
    this.valor = valor
    this.cantidad = cantidad
    this.nombre = nombre
  }

  static toDomain({
    cantidad,
    nombre,
    redVariableId,
    valor,
  }: TRaw) {
    return new EnvioVariable({
      cantidad,
      nombre,
      redVariableId,
      valor
    })
  }
}
