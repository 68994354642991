<template>
  <div
    class="navbar color-primary shadow w-full flex justify-center text-white"
  >
    <div class="flex justify-between items-center px-2 ">
      <div id="ellogo" class="flex items-center">
        <router-link to="/">
          <img class="w-14 mr-2 " style="height: 75px;" src="/img/logo_navbar.svg" alt="" />
        </router-link>
      </div>
      <div class="hidden lg:block">
        <TabMenu
        v-model:activeIndex="activeTabMenu"
        :model="items"
        />

      </div>
      <div class="flex">
        <button
        type="button"
        class="text-white mi-btn mi-btn-sm"
        @click="openNotify"
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 0 24 24" width="32px" fill="white" ><path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z"/></svg>
        </button>
      </div>
      <div class="flex flex-col">
        <div class="flex items-center">
          <div
            class="bg-blue-900 mr-2 rounded-full"
            style="height: 50px; width: 50px"
          ></div>
          <div class="flex-col justify-start flex items-start" >
            <p class="font-black capitalize mb-1">{{ getUsuario.nombres }}</p>
            <button
              type="button"
              class="mi-btn mi-btn-danger mi-btn-sm"
              title="Cerrar sesión"
              @click="onLogout"
            >
              Cerrar sesión
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Sidebar v-model:visible="isModalOpen"  position="right">
    <div class="mb-2">
      <h1 class="font-bold uppercase">Centro de notificaciones</h1>
    </div>
    <div class="flex flex-col">
      <div
      v-for="(row,k) in getNotificaciones" :key="k">
        <div>
          <div class="font-bold">{{row.title}}</div>
          <div>{{row.message}}</div>
          <!-- <div class="text-xs text-right">{{row.createdAt}}</div> -->
          <div class="text-xs text-right">{{formatterFecha(row.createdAt)}}</div>
          <hr class="mb-4">
        </div>
      </div>
    </div>
  </Sidebar>
</template>
<script lang="ts">
export default {
  name: 'NavBar',
}
</script>
<script lang="ts" setup>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import TabMenu from "primevue/tabmenu";
import Sidebar from 'primevue/sidebar';
import {formatterFecha} from "@/helpers/date.helper"
import { canSeeProductosBanner } from "@/const/banners";

const activeTabMenu = ref(0)
const store = useStore();
const router = useRouter();
const isModalOpen = ref(false)

type AuthUser = {
  id: string;
  nombres: string;
  permisos: string[];
  roles: number[];
}
const getUsuario = computed<AuthUser>(() => {
  const user = store.getters["auth/user"];
  if (user) return user;
  return {
    nombres: "",
    permisos: []
  };
});
const onLogout = () => {
  store.dispatch("auth/logout");
  router.push({ name: "auth.login" });

  /**
   * 2024-04-26
   * esto es para controlar que muestre un banner de productos
   * cuando el comercio hace login
   */
  window.localStorage.removeItem(canSeeProductosBanner)
};
const items = computed(()=>{
  const menus = [
    {
      label: 'Inicio',
      url: '/',
      order: 0,
      //command: ()=> router.push('/'),
    }
  ]
  if(getUsuario.value.permisos.some(a => a === 'dashboard_acceso')) {
    menus.push({
      label: "Dashboard",
      url: "/dashboard",
      order: 1,
      //command: ()=> router.push('/dashboard'),
    })
  }

  if(getUsuario.value.permisos.some(a => a === 'trafico_acceso')) {
    menus.push({
      label: "Tráfico",
      url: "/trafico",
      order: 2,
      //command: ()=> router.push('/envios'),
    })
  }

  if(getUsuario.value.permisos.some(a => a === 'clientes_acceso')) {
    menus.push({
      label: "Clientes",
      url: "/clientes",
      order: 3,
      //command: ()=> router.push('/clientes'),
    })
  }

  if(getUsuario.value.permisos.some(a => a === 'tarifas_acceso')) {
    menus.push({
      label: "Tarifas",
      url: "/tarifas",
      order: 4,
      //command: ()=> router.push('/tarifas'),
    })
  }

  if(getUsuario.value.permisos.some(a => a === 'facturar_envios_acceso')) {
    menus.push({
      label: "Facturar Envíos",
      url: "/facturar-envios",
      order: 5,
      //command: ()=> router.push('/facturar-envios'),
    })
  }

  if(getUsuario.value.permisos.some(a => a === 'gestion_facturas_acceso')) {
    menus.push({
      label: "Gestión de Facturas",
      url: "/gestion-facturas",
      order: 6,
      //command: ()=> router.push('/gestion-facturas'),
    })
  }

  if(getUsuario.value.permisos.some(a => a === 'redes_acceso')) {
    menus.push({
      label: "Redes",
      url: "/redes",
      order: 9,
      //command: ()=> router.push('/redes'),
    })
  }

  if(getUsuario.value.permisos.some(a => a === 'mis_datos_acceso')) {
    menus.push({
      label: "Mis Datos",
      url: `/clientes/form/${clienteId.value}`,
      order: 11,
      //command: ()=> router.push(`/clientes/form/${clienteId.value}`),

    })
  }

  return menus.sort((a,b)=> a.order - b.order)
})
const getNotificaciones = computed(()=>store.getters['notify/getListado'])
const openNotify = ()=>{
  store.dispatch('notify/fetchNotify')
  isModalOpen.value = !isModalOpen.value
}
const clienteId = computed(()=>{
  const hasRolCliente = store.getters["auth/user"].roles.some((a:number)=>a===2)
  if (hasRolCliente) return store.getters["auth/user"].cliente.id
  return store.getters["auth/user"].id
})


</script>
<style lang="scss" scoped>
$mezu-primary: #0C8599;
.navbar {
  font-size: 1em;
}
.color-primary {
  background: #0C8599;
}
::v-deep(.p-tabmenu) {

  background-color: #0C8599;
  .p-tabmenu-nav{
    .p-tabmenuitem{
      background-color: #0C8599;
      .p-menuitem-link{
        background-color: #0C8599;
        border-bottom-color: $mezu-primary;
        color: rgba(255,255,255,.35);
        &:hover{
          background: $mezu-primary !important;
          color: white !important;
          border-bottom-color: white !important;
        }
      }
    }
    .p-highlight{
      .p-menuitem-link{
        border-bottom-color: white;
      }
      .p-menuitem-text{
        color: white !important;
      }
    }

  }
}

</style>
