<template>
  <router-view/>
  <Toast />
  <Toast group="grupo1" />
</template>
<script lang="ts" setup>
import Toast from 'primevue/toast'
import socket from '@/libs/socket.lib'
import {useToast} from 'primevue/usetoast'
const toast = useToast()
socket.on('centro-notificaciones',(value)=>{
  console.log('socket centro-notificaciones',{value});
  toast.add({
    severity: 'info',
    summary: value.title,
    detail: value.message,
    group: 'grupo1',
    life: 4000,
  })
})
socket.on('envios.notiticaciones',(value)=>{
  //console.log('envios.notiticaciones',value);
  //const data = JSON.parse(value.data)
  //console.log(data);
  new Audio('/sounds/notification.mp3').play()
  toast.add({
    severity: 'info',
    summary: value.title,
    detail: value.message,
    group: 'grupo1',
    life: 1000 * 10,
  })
})

</script>
<style lang="scss">
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;700;900&display=swap');

#app {
  font-family: 'Hind', sans-serif;
  text-align: center;
  color: #2c3e50;
  font-size: 100%;
  font-weight: 300;


  h1,h2,h3,h4,h5,h6{
    font-weight: 700;
    color: #46719C;
  }
  h1{
    font-size: 2em;
  }
  h2{
    font-size: 1.5em;
  }
  h3{
    font-size: 1em;
  }
  h4{
    font-size: 0.5em;
  }

  p{
    font-size: 0.885em;
  }
}
.swal2-container{
  z-index: 20000 !important;
}
</style>
